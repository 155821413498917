export default {
  properties: {
    step: {
      type: 'number'
    },
    value_type: {
      type: 'string'
    },
    min: {
      type: 'number'
    },
    max: {
      type: 'number'
    }
  }
};