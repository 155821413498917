export const CMS_BRANCH_PREFIX = 'cms';
export const DEFAULT_PR_BODY = 'Automatically generated by Decap CMS';
export const MERGE_COMMIT_MESSAGE = 'Automatically generated. Merged on Decap CMS.';
const DEFAULT_DECAP_CMS_LABEL_PREFIX = 'decap-cms/';
function getLabelPrefix(labelPrefix) {
  return labelPrefix || DEFAULT_DECAP_CMS_LABEL_PREFIX;
}
export function isCMSLabel(label, labelPrefix) {
  return label.startsWith(getLabelPrefix(labelPrefix));
}
export function labelToStatus(label, labelPrefix) {
  return label.slice(getLabelPrefix(labelPrefix).length);
}
export function statusToLabel(status, labelPrefix) {
  return `${getLabelPrefix(labelPrefix)}${status}`;
}
export function generateContentKey(collectionName, slug) {
  return `${collectionName}/${slug}`;
}
export function parseContentKey(contentKey) {
  const index = contentKey.indexOf('/');
  return {
    collection: contentKey.slice(0, index),
    slug: contentKey.slice(index + 1)
  };
}
export function contentKeyFromBranch(branch) {
  return branch.slice(`${CMS_BRANCH_PREFIX}/`.length);
}
export function branchFromContentKey(contentKey) {
  return `${CMS_BRANCH_PREFIX}/${contentKey}`;
}